import React from 'react';
// import PropTypes from 'prop-types';

const AppStoreIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.57 0H23.43C27.0586 0 30 2.94141 30 6.57V23.43C30 27.0586 27.0586 30 23.43 30H6.57C2.94141 30 0 27.0586 0 23.43V6.57C0 2.94141 2.94141 0 6.57 0Z"
        fill="url(#paint0_linear_1109_960)"
      />
      <path
        d="M9.61563 21.7745L9.61844 21.7754L8.5927 23.552C8.21828 24.2005 7.38906 24.4227 6.74055 24.0483C6.09215 23.6739 5.86984 22.8446 6.24426 22.1961L6.99988 20.8874L7.07242 20.7618C7.20191 20.5757 7.52148 20.2543 8.16074 20.3148C8.16074 20.3148 9.66508 20.478 9.77395 21.2599C9.77395 21.2599 9.78871 21.5171 9.61563 21.7745ZM24.1636 17.237H20.9651C20.7473 17.2223 20.6522 17.1445 20.6147 17.0993L20.6124 17.0952L17.1885 11.1648L17.1841 11.1677L16.9787 10.8734C16.6422 10.3587 16.1078 11.6749 16.1078 11.6749C15.4697 13.1416 16.1984 14.8091 16.4523 15.3131L21.208 23.5502C21.5823 24.1986 22.4116 24.4209 23.0602 24.0464C23.7086 23.672 23.9309 22.8428 23.5564 22.1943L22.3673 20.1346C22.3442 20.0846 22.3041 19.9492 22.548 19.9486H24.1636C24.9125 19.9486 25.5195 19.3416 25.5195 18.5927C25.5195 17.8439 24.9125 17.2369 24.1636 17.2369V17.237ZM17.9511 19.0801C17.9511 19.0801 18.1218 19.9486 17.4612 19.9486H5.63711C4.88828 19.9486 4.28125 19.3416 4.28125 18.5927C4.28125 17.8439 4.88828 17.2369 5.63711 17.2369H8.67695C9.16773 17.2085 9.28398 16.9252 9.28398 16.9252L9.2868 16.9266L13.2548 10.0537L13.2536 10.0535C13.3259 9.92074 13.2657 9.79523 13.2552 9.77543L11.9447 7.50562C11.5703 6.85722 11.7925 6.02789 12.441 5.65359C13.0895 5.27918 13.9188 5.50125 14.2932 6.14976L14.9009 7.20246L15.5076 6.15164C15.882 5.50324 16.7112 5.28093 17.3597 5.65546C18.0082 6.02988 18.2304 6.85898 17.856 7.5075L12.3344 17.0712C12.3102 17.1294 12.3029 17.2208 12.4825 17.2369H15.783L15.7837 17.2691C15.7837 17.2691 17.6913 17.2987 17.9511 19.0801Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1109_960"
          x1="15"
          y1="0"
          x2="15"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17C9FB" />
          <stop offset="1" stopColor="#1A74E8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const AppStoreButton = () => {
  return (
    <div className="app-store-btn">
      <AppStoreIcon />
      <span className="text">App Store</span>
    </div>
  );
};

AppStoreButton.propTypes = {};

AppStoreButton.defaultProps = {};

export default AppStoreButton;
