import React from 'react';
// import PropTypes from 'prop-types';

const PlayStoreIcon = () => {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6503 19.585C24.2006 17.6336 26.892 16.1466 27.124 16.031C27.8661 15.6359 28.6325 14.5903 27.124 13.7781C26.6369 13.5227 24.0147 12.082 20.6503 10.2241L15.9863 14.9393L20.6503 19.5849V19.585Z"
        fill="#FFD900"
      />
      <path
        d="M15.9873 14.9395L1.13672 29.9223C1.4853 29.9685 1.87883 29.876 2.3428 29.6206C3.31689 29.0869 13.6432 23.4417 20.6513 19.5863L15.9873 14.9395Z"
        fill="#F43249"
      />
      <path
        d="M15.987 14.9391L20.6509 10.2472C20.6509 10.2472 3.38697 0.815679 2.34244 0.258927C1.94891 0.0256061 1.50791 -0.0437005 1.11328 0.0256061L15.987 14.9391Z"
        fill="#00EE76"
      />
      <path
        d="M15.9875 14.9394L1.11377 0.0258789C0.510125 0.165587 0 0.699455 0 1.79128V28.1568C0 29.1561 0.394734 29.8759 1.13684 29.9453L15.9875 14.9394Z"
        fill="#00D3FF"
      />
    </svg>
  );
};

const PlayStoreButton = () => {
  return (
    <div className="play-store-btn">
      <PlayStoreIcon />
      <span className="text">Google Play</span>
    </div>
  );
};

PlayStoreButton.propTypes = {};

PlayStoreButton.defaultProps = {};

export default PlayStoreButton;
