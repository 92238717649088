import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';

// assets
import { FaApple, FaReact, FaAngular, FaAws } from 'react-icons/fa';
import { ImAndroid } from 'react-icons/im';
import {
  SiFlutter,
  SiPython,
  SiPhp,
  SiFirebase,
  SiPostgresql,
  SiGooglecloud,
  SiDigitalocean,
  SiMongodb,
} from 'react-icons/si';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GrNode } from 'react-icons/gr';
import CardTypeFour from '../components/CardTypeFour';
import AppStoreButton from '../components/AppStoreButton';
import PlayStoreButton from '../components/PlayStoreButton';

const technologies = [
  { icon: <FaApple size={50} />, key: 'ios' },
  { icon: <ImAndroid size={50} />, key: 'android' },
  { icon: <FaReact size={50} />, key: 'react-native' },
  { icon: <SiFlutter size={50} />, key: 'flutter' },
  { icon: <FaAngular size={50} />, key: 'angular' },
  { icon: <FaReact size={50} />, key: 'react' },
  { icon: <GrNode size={50} />, key: 'nodejs' },
  { icon: <SiPython size={50} />, key: 'python' },
  { icon: <SiPhp size={50} />, key: 'php' },
  { icon: <SiFirebase size={50} />, key: 'firebase' },
  { icon: <SiPostgresql size={50} />, key: 'postgresql' },
  { icon: <FaAws size={50} />, key: 'aws' },
  { icon: <SiGooglecloud size={50} />, key: 'google-cloud' },
  { icon: <SiDigitalocean size={50} />, key: 'digital-ocean' },
  { icon: <SiMongodb size={50} />, key: 'mongodb' },
];

const CaseStudy = ({ data, location }) => {
  const { fields } = data.allSitePage.edges[0].node.context;

  return (
    <Layout pageId="case-study" location={location} footer={true}>
      <Seo title={fields.pageTitle} />
      <Section id="hero" backgroundColor="#FFF">
        <Container className="flex-col pb-24 lg:pb-14 xl:pb-14">
          <span className="flex-1 flex items-center justify-center w-full mt-20">
            <div className="product-info">
              <h1 className=" text-black fs-36 fs-lg-64 fw-600 m-0">
                {fields.pageTitle}
              </h1>
              <p className="description">{fields.hero.productDescription}</p>
              <div className="links">
                {fields.hero.productLinks.map((link, index) => (
                  <span key={index} className="link-btn">
                    {link.source === 'app-store' && <AppStoreButton />}
                    {link.source === 'play-store' && <PlayStoreButton />}
                  </span>
                ))}
              </div>
            </div>
            <div className="product-banner" />
          </span>
        </Container>
      </Section>

      <Section id="overview">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="title ff-primary fw-300 fs-36 fs-lg-48">
              Over<span className="text-primary fw-400">view</span>
            </h2>
          </SectionHeading>
          <div className="description">{fields.overview.text}</div>
        </Container>
      </Section>

      <Section id="problem-statement" backgroundColor="#FFF">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="title ff-primary text-black fw-300 fs-36 fs-lg-48">
              Problem <span className="text-primary fw-400">Statement</span>
            </h2>
          </SectionHeading>
          <div className="description">{fields.problemStatement.text}</div>
        </Container>
      </Section>

      <Section id="user-flow">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="user-flow__title ff-primary fw-300 fs-36 fs-lg-48">
              User <span className="text-primary fw-400">Flow</span>
            </h2>
          </SectionHeading>
          <div className="user-flow__description">{fields.userFlow.text}</div>
          <div className="user-flow__diagram" />
        </Container>
      </Section>

      <Section id="design-elements" backgroundColor="#FFF">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="design-elements__title ff-primary text-black fw-300 fs-36 fs-lg-48">
              Design <span className="text-primary fw-400">Elements</span>
            </h2>
          </SectionHeading>
          <div className="design-elements__diagram" />
        </Container>
      </Section>

      <Section id="technologies-used">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="technologies-used__title ff-primary fw-300 fs-36 fs-lg-48">
              Technologies <span className="text-primary fw-400">Used</span>
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {technologies.map(({ icon, key }, index) => {
              return (
                fields.technologiesUsed.includes(key) && (
                  <div key={index}>
                    <span className="icon">{icon}</span>
                  </div>
                )
              );
            })}
          </div>
        </Container>
      </Section>

      <Section id="ui-screens" backgroundColor="#FFF">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="ui-screens__title ff-primary text-black fw-300 fs-36 fs-lg-48">
              <span className="text-primary fw-400">UI</span> Screens
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="card" />
            ))}
          </div>
        </Container>
      </Section>

      <Section id="challenges-and-solutions">
        <Container>
          <div className="grid-container grid grid-cols-2">
            <div>
              <h4 className="title">Challenges</h4>
              <CardTypeFour data={fields.challenges} />
            </div>
            <div>
              <h4 className="title mt-14 lg:mt-0">Solutions</h4>
              <CardTypeFour data={fields.solutions} />
            </div>
          </div>
        </Container>
      </Section>

      <Section id="key-features">
        <Container>
          <SectionHeading className="ff-primary fs-36 fs-lg-48">
            <h2 className="technologies-used__title ff-primary fw-300 fs-36 fs-lg-48">
              Key Features
            </h2>
          </SectionHeading>
          <div className="grid-container grid grid-cols-2">
            <div className="key-features__image" />
            <div className="key-features__description">
              {fields.keyFeatures.text}
            </div>
          </div>
        </Container>
      </Section>

      <Section id="next-case-study" backgroundColor="#FFF">
        <Container>
          <h3 className="title ff-tertiary text-black">Next Case Study</h3>
          <div className="next">
            <div className="thumbnail" />
            <div className="next-icon">
              <MdKeyboardArrowRight />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

CaseStudy.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default CaseStudy;

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            slug
            fields {
              pageId
              pageTitle
              hero {
                productDescription
                productLinks {
                  source
                  src
                }
              }
              overview {
                text
              }
              problemStatement {
                text
              }
              userFlow {
                text
              }
              technologiesUsed
              challenges {
                text
              }
              solutions {
                text
              }
              keyFeatures {
                text
              }
            }
          }
        }
      }
    }
  }
`;
