import React from 'react';
import PropTypes from 'prop-types';

import { FiCheckCircle } from 'react-icons/fi';

const CardTypeFour = ({ data }) => {
  return (
    <div className="card-type-four">
      {data.map(({ text }, index) => (
        <div key={index} className="list-items">
          <span>
            <FiCheckCircle size={30} className="icon" />
          </span>
          <span className="text">{text}</span>
        </div>
      ))}
    </div>
  );
};

CardTypeFour.propTypes = {
  data: PropTypes.array,
};

CardTypeFour.defaultProps = {
  list: [],
};

export default CardTypeFour;
