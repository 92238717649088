import React from 'react';
import PropTypes from 'prop-types';

const SectionHeading = ({ theme, subTitle, style, children, className }) => {
  return (
    <span className="w-full" style={style}>
      <h3
        className={`${
          theme === 'light' ? 'text-white' : 'text-black'
        } ff-primary fs-36 fs-lg-46 fw-300 ${className}`}
      >
        {children}
      </h3>
      {subTitle && (
        <h6 className="fs-13 fs-lg-15 mt-2" style={{ color: '#868686' }}>
          {subTitle}
        </h6>
      )}
    </span>
  );
};

SectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  subTitle: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

SectionHeading.defaultProps = {
  theme: 'light',
  style: {},
  className: '',
};

export default SectionHeading;
